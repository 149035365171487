import styled from "styled-components";
import * as React from "react";
import { randomPastelColor } from "../helpers/randomPastelColor";
import { formatCreatedAt } from "../helpers/formatCreatedAt";
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

const Title = styled(Link)<{ underlineColor: string }>`
  display: inline;
  border-bottom: none;
  background-image: linear-gradient(
    180deg,
    transparent 65%,
    #${(props) => props.underlineColor} 0
  );
  background-size: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.75rem !important;
  margin-top: 24px;
  line-height: 1rem;

  &:hover {
    background-size: 100% 100%;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem !important;
  }
`;

const Container = styled.div`
  font-weight: 300;
  font-size: 24px;
  max-width: 560px;
  margin-bottom: 30px;

  display: flex;
  flex-direction: column;

  a {
    color: black;
    font-weight: bold;
    font-size: 18px;
    vertical-align: 5%;
    text-decoration: none;
  }

  :hover {
    a {
      background-size: 100% 100%;
    }
  }

  @media (max-width: 768px) {
    font-size: 18px;
    a {
      font-size: 14px;
    }
  }
`;

const Description = styled.p`
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 1.25;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CreatedAt = styled.div`
  font-size: 14px;
  color: #737373;
  font-family: "Roboto Condensed", sans-serif;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export interface ListItemProps {
  createdAt: string;
  description: string;
  image: IGatsbyImageData;
  imageAlt: string;
  slug: string;
  text: string;
}

export const ListItem = ({
  createdAt,
  text,
  imageAlt,
  slug,
  image,
  description,
}: ListItemProps) => (
  <Container>
    <Link to={`/${slug}`}>
      <GatsbyImage alt={imageAlt} image={image} />
    </Link>
    <div>
      <Title underlineColor={randomPastelColor()} to={`/${slug}`}>
        {text}
      </Title>
    </div>
    <CreatedAt>{formatCreatedAt(createdAt)}</CreatedAt>
    <Description>{description}</Description>
  </Container>
);
