import styled from "styled-components";

export const MasonGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-gap: 24px;
  margin-bottom: 24px;

  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }

  div:first-child {
    grid-column: span 2;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
    margin-bottom: 12px;
    div:first-child {
      grid-column: span 1;
    }
  }
`;