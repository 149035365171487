import * as React from "react";
import { MasonGrid } from "../components/MasonGrid";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { ListItem, ListItemProps } from "../components/ListItem";
import { GlobalStyle } from "../components/GlobalStyle";
import { PageContainer } from "../components/PageContainer";
import { graphql, PageProps } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { ucfirst } from "../helpers/ucfirst";
import Seo from "../components/Seo";

const CategoryPage = (
  params: PageProps<Queries.CategoryPageQuery, { tag: string }>
) => {
  const { data } = params;
  if (!data?.allMdx?.edges) return null;

  const links: Array<ListItemProps> = data.allMdx.edges.map(
    ({ node }: Queries.CategoryPageQuery["allMdx"]["edges"][0]) => ({
      text: node.frontmatter?.title ?? "",
      description: node.frontmatter?.description || "",
      createdAt: node.frontmatter?.createdAt ?? "",
      slug: node.slug ?? "",
      image:
        node.featuredImg?.childImageSharp?.gatsbyImageData ??
        ({} as IGatsbyImageData),
      imageAlt: node.frontmatter?.imageAlt ?? "",
    })
  );

  return (
    <>
      <Seo
        title={ucfirst(params.pageContext.tag)}
        description={`Articles à propos du sujet "${params.pageContext.tag}"`}
      />
      <GlobalStyle />

      <PageContainer>
        <Header />
        <MasonGrid columns={3}>
          {links?.map((link) => (
            <ListItem key={link.slug} {...link} />
          ))}
        </MasonGrid>
      </PageContainer>
      <Footer />
    </>
  );
};

export const query = graphql`
  query CategoryPage($tag: String!) {
    allMdx(filter: { frontmatter: { tags: { in: [$tag] } } }) {
      edges {
        node {
          frontmatter {
            title
            createdAt
            description
            imageAlt
          }
          featuredImg {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 400)
            }
          }
          slug
        }
      }
    }
  }
`;

export default CategoryPage;
